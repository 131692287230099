.react-kanban-column {
    display: inline-block;
    padding: 15px;
    border-radius: 5px !important;
    background-color: #224aac !important;
    margin: 5px;
    vertical-align: top;
}


@media (max-width: 1824px) {
    .borad-width {
        width: 345px;
    }
}
@media (max-width: 1568px) {
    .borad-width {
        
        width: auto;
    }
}

@media (max-width: 1224px) {
    .borad-width {
        
        width: auto;
    }
}