.pt-10 {
    height: 100% !important;
}
.img {
    max-width: 100%;
    height: auto;
}
.login-page {
    height: 100vh;
    display: flex;
    background-image: url("/assets/images/others/bgloginnew.png");
    inset: 0px;
    background-size: cover;
    min-width: 100%;
    min-height: 100%;
}
.style-login {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.box-flex-2 {
    width: 100%;
    display: flex;
    justify-content: center;
}
.box-flex,
.box-logo,
.logo-img {
    display: none;
}
.bt-style-joborder {
    max-width: 250px !important;
    width: 100% !important;
    background-color: #ffffff !important;
    border: 2px solid #c2c2c2 !important;
    border-radius: 50px !important;
    margin-top: 20px;
}

@media screen and (min-width: 992px) {
    .box-flex-1 {
        width: 40%;
        display: flex;
        justify-content: center;
        flex-flow: wrap;
    }

    .box-logo {
        display: flex;
        width: 75%;
        height: 100%;
    }
    .logo-img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        height: auto;
    }
    .box-flex-2 {
        width: 50%;
    }
    .bt-none {
        display: none !important;
    }
}
@media screen and (max-width: 991px) {
    .style-login {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .card-login {
        min-height: 480px !important;
    }
    .card-search {
        min-height: 240 !important;
    }
}

@media screen and (min-width: 1300px) {
    .box-logo {
        display: flex;
        width: 80%;
        height: 100%;
    }
}
@media screen and (min-width: 2007px) {
    .box-logo {
        display: flex;
        width: 100%;
        height: 100%;
    }
}

.card-login {
    display: grid;
    grid-template-rows: fit-content(100%) auto;
    width: 400px;
    height: 50%;
    min-height: 450px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
    border-radius: 10px;
    padding: 10px 30px 20px 30px;
}

.card-search {
    display: grid;
    grid-template-rows: fit-content(100%) auto;
    width: 400px;
    height: 50%;
    // min-height: 450px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
    border-radius: 10px;
    padding: 10px 30px 20px 30px;
}
.card-text {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    color: black;
}
.style-card-login {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.style-card-search {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.icon-thai,
.icon-eng-gray {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}
.normal-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: black;
}
.normal-text-18 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}
.card-forgot-password {
    display: grid;
    grid-template-rows: fit-content(100%) auto;
    width: 400px;
    height: 50%;
    min-height: 450px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
    border-radius: 10px;
    padding: 10px 30px 20px 30px;
}
