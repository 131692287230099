.topnavbar {
    height: 3rem;
    background-color: #224aac;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1002;
    padding: 0 calc(24px / 2);
}

.main-content-intro {
    overflow: hidden;
}

.page-content-intro {
    padding: calc(104px + 24px) calc(0px / 2) 0px calc(0px / 2);
}

.container-intro {
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    height: 5rem;
    background-color: #fff;
    border-bottom: 1px solid #dfdfdf;
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
    z-index: -1;
    padding: 0 calc(24px / 2);
}

.logo-tourism {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin: -1rem 13rem; */
    margin: 1.5rem 13rem;
}

.logo-tourism img {
    width: 100px;
}

.text-logo {
    margin-top: 15px;
}

.contain-body {
    /* margin: 8rem auto; */
    display: flex;
    flex-direction: row;
    background-color: #fff;
}

.content-row {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem;
    justify-content: space-between;
}

.content-row-no-br {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem;
    justify-content: space-between;
}

.manual {
    display: flex;
    justify-content: end;
    align-items: center;
}

.manual-pdf {
    text-align: center;
    margin: 0rem 1rem -3rem;
}

.btn-lg,
.btn-group-lg>.btn {
    padding: 1rem 6rem !important;
    font-size: 20px !important;
}

.style-button {
    padding: 1rem !important;
    width: 250px;
    font-size: 18px !important;
    background-color: rgb(34, 74, 172) !important;
    border-color: rgb(34, 74, 172) !important;
}

.btn-dark,
.btn-secondary {
    color: rgb(34, 74, 172) !important;
}

.qr-guide {
    display: flex;
}

.qr-police {
    display: flex;
}

.qr-police img {
    width: 180px;
}

.download {
    display: block;
    flex-direction: column;
    align-self: center;
}

/* .img-store {
    display: flex;
    height: 30px;
} */

.footer-intro {
    /* margin-top: -8rem; */
    height: 100%;
    background-color: #20346f;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1002;
    padding: 0 calc(24px / 2);
}

.social-media-intro {
    display: flex;
    width: 65%;
    justify-content: center;
    height: 40px;
}

.social-media-intro img {
    margin: 0px 5px 0px 5px;
}

.input-name {
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin-top: 15px;
}

.input-name>input[type="text"] {
    width: 20rem;
    border: none;
    border-bottom: 2px solid #80a8ee;
    outline: none;
    background: transparent;
    color: #fff;
    font-size: 16px;
}

.underline-animation {
    transition: all 0.5s;
    display: inline-block;
    bottom: 0;
    left: -100%;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #64e4fe;
}

.input-name>input[type="text"]:focus+.underline-animation {
    left: 0;
}

.video-style {
    width: 350px;
    height: 250px;
}

.dropdown-intro {
    display: flex;
    justify-content: end;
    margin-top: 5px;
    padding-right: 168px;
}

.font-style-20 {
    font-size: 20px;
}

.font-style-24 {
    font-size: 24px;
}

.font-style-20-bold {
    font-size: 20px;
    font-weight: bold;
}

.font-style-18 {
    font-size: 18px;
}

.font-style-18-bold {
    font-size: 18px;
    font-weight: bold;
}

.font-style-30-bold {
    font-size: 30px;
    font-weight: bold;
}

.mr-15 {
    margin-top: 15px;
}

.mr-15-end {
    margin-top: 15px;
    text-align: end;
}

.btn-intro {
    display: inline-block;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid white;
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.call-center-phone {
    margin: 10px 25px 0px 25px;
}

.call-center-phone p {
    font-size: 18px;
}

@media screen and (max-width: 992px) {
    .left-sidebar {
        display: none;
    }

    .right-sidebar {
        display: none;
    }
}

/* screen phone */
@media screen and (min-width: 320px) {
    .left-sidebar {
        order: 1;
        flex: 0;
    }

    .content-intro {
        flex: 1;
        order: 2;
    }

    .right-sidebar {
        flex: 0;
        order: 3;
    }

    .hr-intro {
        margin: 0 auto !important;
        width: 94%;
        border-top: 2px solid #c1c1c1 !important;
    }

    .manual-pdf {
        text-align: center;
    }

    /* .sign-in {
        margin-bottom: 3rem;
    } */
}

@media screen and (min-width: 320px) and (max-width:992px) {
    .qr-guide {
        display: flex;
        margin: 0rem 0rem 1rem -1rem;
        justify-content: center;
    }

    .qr-police {
        display: flex;
        margin: 0rem 0rem 1rem -1rem;
        justify-content: center;
    }
}

@media screen and (min-width: 992px) {
    .qr-guide {
        display: flex;
        margin: 0rem 0rem 1rem -1rem;
    }

    .qr-police {
        display: flex;
        margin: 0rem 0rem 1rem -1rem;
    }
}

@media screen and (min-height: 1024px) {
    .footer-intro {
        height: 95vh;
    }
}

@media screen and (min-width: 562px) and (max-width: 992px) {
    .logo-tourism {
        /* margin: -1.5rem 1rem; */
        margin: 1.5rem 1rem;
    }

    .text-logo {
        margin: 30px 0px 0px 5px;
    }

    .dropdown-intro {
        padding-right: 20px;
    }
}

@media screen and (min-width: 375px) and (max-width: 561px) {
    .logo-tourism {
        /* margin: -1rem 1rem; */
        margin: 2rem 1rem;
    }

    .logo-tourism img {
        width: 80px;
    }

    .text-logo {
        margin: 25px 0px 0px 10px;
    }

    .dropdown-intro {
        padding-right: 20px;
    }
}

@media screen and (min-width: 500px) and (max-width: 1199px) {
    .manual {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
    .content-row {
        flex-flow: nowrap;
    }
}

.call-center img {
    width: 100%;
}

.qr-guide img {
    width: 180px;
}

.qr-police img {
    width: 180px;
}

.img-store {
    display: flex;
    height: 30px;
    width: 10px;
}

@media screen and (min-width: 375px) and (max-width: 499px) {
    .content-row {
        margin: 1rem;
    }

    .content-row-no-br {
        margin: 1rem;
    }

    .style-button {
        padding: 0.5rem !important;
        width: 35%;
        font-size: 14px !important;
        background-color: rgb(34, 74, 172) !important;
        border-color: rgb(34, 74, 172) !important;
    }

    .manual {
        display: flex;
        align-items: flex-start;
        flex-flow: column;
    }

    .video-style {
        width: 320px;
        height: 250px;
    }

    .video-mb {
        margin-top: 4rem;
    }

    .font-style-20 {
        font-size: 16px !important;
    }

    .font-style-18 {
        font-size: 14px !important;
    }

    .font-style-20-bold {
        font-size: 14px !important;
        font-weight: bold;
    }

    .font-style-18-bold {
        font-size: 22px !important;
        font-weight: bold;
    }

    .font-style-30-bold {
        font-size: 20px !important;
        font-weight: bold;
    }

    .font-style-24 {
        font-size: 18px !important;
    }

    .mr-15 {
        margin: 15px;
    }

    .mr-15-end {
        margin: 15px;
    }

    .qr-guide img {
        width: 160px;
    }

    .qr-police img {
        width: 160px;
    }

    .img-store {
        display: block;
        height: 30px;
        width: 10px;
    }

    .call-center-phone {
        margin: 10px 0px 0px 0px;
    }

    .call-center-phone p {
        font-size: 14px;
    }

    .style-qr-code {
        display: block;
        text-align: center;
    }
}

@media screen and (min-width: 500px) and (max-width: 720px) {
    .mr-15 {
        margin: 15px;
    }

    .qr-guide img {
        width: 160px;
    }

    .qr-police img {
        width: 160px;
    }
}

@media screen and(min-width: 1301px) and (max-width: 1500px) {
    .img-store {
        display: block;
        height: 30px;
    }
}