.remove_shadow {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
}

@media only screen and (min-width: 375px) and (max-width: 767px) {
        .mt-button-1 {
                margin-top: 1rem;
        }
}

@media only screen and (min-width: 1200px) and (max-width: 1292px) {
        .w-button-1 {
                width: 110% !important;
        }
}
